import React from 'react';
import styled from 'styled-components';
import LightGallery from 'lightgallery/react';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import 'lightgallery/scss/lg-thumbnail.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import {ContainerLg} from '../components';
import {graphql, useStaticQuery} from 'gatsby';
import {getSrc, ImageDataLike} from 'gatsby-plugin-image';

const StyledGallerySection = styled.section.attrs({
  className: 'gallery-section'
})`
  margin: 2rem 0;
  .container-lg {
    .lg-react-element {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
      text-align: center;
    }
  }
`;

const GalleryDinnerSection = () => {
  const images = useStaticQuery(graphql`
    query {
      thumbnails: allFile(filter: {absolutePath: {regex: "/images/dinner/"}}) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              width: 340
              height: 226
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      fullSize: allFile(filter: {absolutePath: {regex: "/images/dinner/"}}) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  `);

  const onInit = () => {
    // console.log('lightGallery has been initialized');
  };

  return (
    <StyledGallerySection>
      <ContainerLg>
        <LightGallery
          onInit={onInit}
          speed={500}
          plugins={[lgThumbnail, lgZoom]}
          mode="lg-fade"
          licenseKey="70FF22A5-21CE4839-ACAE13BD-123A3732"
        >
          {images.thumbnails.nodes.map((node: ImageDataLike, index: number) => {
            return (
              <a key={index} href={getSrc(images.fullSize.nodes[index])}>
                <img alt="Cinestar Cinema - BIG Novi Sad" src={getSrc(node)} />
              </a>
            );
          })}
        </LightGallery>
      </ContainerLg>
    </StyledGallerySection>
  );
};

export default GalleryDinnerSection;
