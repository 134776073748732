import {DefaultTheme} from 'styled-components';

export const COLORS = {
  // ARMADA JS COLORS
  primary: '#100f21',
  blueLight: '#1babc8',
  yellow: '#f6df19',
  yellowLight: '#f7f01b',
  yellowDark: '#fcc100',
  armadaDark: '#0f0f20',
  // REST
  highlight: '#23DCFD',
  white: '#FFFFFF',
  black: '#000000',
  gray: 'rgba(0, 0, 0, 0.6)',
  lightGray: 'rgba(35,55,65, 0.8)',
  lightPageBackground: '#FAFAFA',
  darkGrey: '#858A95',
  // primary: '#269DF0',
  offBlack: '#363f59',
  primaryLight: '#00BBF6',
  darkBlue: '#0B162B',
  violetButton: '#5558db',
  voiletButtonHover: '#6669DF',
  darkVioletBackground: '#131548',
  violetBorderButton: '#9597e9',
  headlineSubtitleColor: '#0b162b99',
  darkGreyBackground: '#121621',
  darkBackground: '#09101d',
  lightGreyBackground: '#f7f8fa',
  lightBtnBgd: '#ECECF3',
  lightBlack: '#0e111a',
  green: '#4BB34B',
  azure100: '#7A90CC',
  azure200: '#54638C',
  lime90: '#0ABD9E',
  lime100: '#18BA9E'
};

const FontSizes = {
  ultraHeading: '48px',
  h1: '36px',
  h2: '20px',
  h3: '18px',
  h4: '16px',
  btnText: '16px',
  p1: '16px'
};

const LineHeights = {
  ultraHeading: '64px',
  h1: '110%',
  h2: '110%',
  h3: '110%',
  h4: '110%',
  btnText: '110%',
  p1: '180%'
};

const FontWeight = {
  ultraHeading: 400
};

const LetterSpacing = {
  ultraHeading: '0.5px',
  h1: '0.5px'
};

export const breakPoints = {
  xl: 1300,
  lg: 1000,
  md: 768,
  sm: 500
};

export const breakpointsMediaQueries = {
  xl: `@media (max-width: ${breakPoints.xl}px)`,
  lg: `@media (max-width: ${breakPoints.lg}px)`,
  md: `@media (max-width: ${breakPoints.md}px)`,
  sm: `@media (max-width: ${breakPoints.sm}px)`
};

const myTheme: DefaultTheme = {
  ...COLORS,
  fontColor: COLORS.black,
  fontColorLT: COLORS.lightGreyBackground,
  highlightColor: COLORS.highlight,
  darkBlue: COLORS.darkBlue,
  darkSectionBackground: COLORS.darkBackground,
  paragraphColor: COLORS.offBlack,
  fontFamily: '"Graphik Web"',
  fontFamilySemiBold: '"Graphik Web Semibold"',
  fontFamilyMedium: '"Graphik Web Medium"',
  containerMaxWidth: breakPoints.xl,
  containerMinWidth: 375,
  violetBtn: COLORS.violetButton,
  violetBorderBtn: COLORS.violetBorderButton,
  transition: 'all .4s ease',
  primaryBtnBgd: COLORS.primary,
  primaryBtnColor: 'white',
  primaryBtnBgdHover: COLORS.voiletButtonHover,
  headlineSubtitleColor: COLORS.headlineSubtitleColor,
  darkVioletHero: COLORS.darkVioletBackground,
  sectionPadding: '80px 0 100px 0',
  sectonContentPadding: '80px',
  gray: COLORS.gray,
  carouselButton: COLORS.darkGrey,
  mobileCaptionsColor: COLORS.darkGreyBackground,
  lightBtnColor: COLORS.lightBlack,
  lightBtnBgd: COLORS.lightBtnBgd,
  lightBtnBgdHover: COLORS.lightGreyBackground,
  positiveColor: COLORS.green,
  // Font sizes
  fontSizeUltraHeading: FontSizes.ultraHeading,
  fontSizeH1: FontSizes.h1,
  fontSizeH2: FontSizes.h2,
  fontSizeH3: FontSizes.h3,
  fontSizeH4: FontSizes.h4,
  fontSizeBtnText: FontSizes.btnText,
  fontSizeP1: FontSizes.p1,
  // Font weight
  fontWeightUltraHeading: FontWeight.ultraHeading,
  // Line heights
  lineHeightUltraHeading: LineHeights.ultraHeading,
  lineHeightH1: LineHeights.h1,
  lineHeightH2: LineHeights.h2,
  lineHeightH3: LineHeights.h3,
  lineHeightH4: LineHeights.h4,
  lineHeightBtnText: LineHeights.btnText,
  lineHeightP1: LineHeights.p1,
  // letter spacing
  letterSpacingUltraHeading: LetterSpacing.ultraHeading,
  letterSpacingH1: LetterSpacing.h1,
  // margin
  sectionMarginTop: 64,
  sectionMarginBottom: 40
};

export {myTheme};
